<template>
  <nav class="navbar navbar-expand navbar-light navbar-bg">
    <a class="sidebar-toggle" @click="toggleSidebar">
      <i class="hamburger align-self-center"></i>
    </a>

    <ul class="navbar-nav">
      <li class="nav-item px-2 dropdown">
        <b-dropdown
          id="project-menu"
          ref="project_menu"
          :text="selectedProjectName"
          toggle-class="nav-link dropdown-toggle"
          menu-class="dropdown-menu dropdown-menu-left dropdown-mega"
          variant="link"
        >
          <div class="d-md-flex align-items-start justify-content-start">
            <div class="dropdown-mega-list">
              <div v-if="searchClient">
                <ais-instant-search
                  :search-client="searchClient"
                  :index-name="searchIndex"
                >
                  <ais-autocomplete>
                    <div slot-scope="{ currentRefinement, indices, refine }">
                      <b-dropdown-form>
                        <b-form-input
                          ref="search"
                          type="search"
                          class="rounded"
                          :value="currentRefinement"
                          placeholder="Search projects..."
                          @input="refine($event)"
                        />
                      </b-dropdown-form>
                      <div v-if="currentRefinement">
                        <b-dropdown-header
                          >{{ indices[0].hits.length }}
                          {{
                            indices[0].hits.length !== 1 ? "results" : "result"
                          }}</b-dropdown-header
                        >
                        <b-dropdown-item
                          v-for="hit in indices[0].hits"
                          :key="hit.objectID"
                          @click="selectProject(hit)"
                        >
                          <ais-highlight attribute="name" :hit="hit" />
                        </b-dropdown-item>
                      </div>
                      <div v-else>
                        <b-dropdown-header>Recents</b-dropdown-header>
                        <b-dropdown-item
                          v-for="recent in recents"
                          :key="recent.objectID"
                          @click="selectProject(recent)"
                        >
                          {{ recent.name }}
                        </b-dropdown-item>
                      </div>
                      <b-dropdown-header>
                        <ais-powered-by />
                      </b-dropdown-header>
                    </div>
                  </ais-autocomplete>
                </ais-instant-search>
              </div>
            </div>
            <div class="dropdown-mega-list">
              <b-dropdown-item :to="{ name: 'projects' }"
                ><h5 class="mt-2">
                  <i class="fas fa-cog"></i> Manage Projects
                </h5></b-dropdown-item
              >
              <b-dropdown-header>Favorites</b-dropdown-header>
              <b-dropdown-item
                v-for="favorite in favorites"
                :key="favorite.objectID"
                @click="selectProject(favorite)"
              >
                {{ favorite.name }}
              </b-dropdown-item>
            </div>
          </div>
        </b-dropdown>
      </li>
    </ul>

    <div class="navbar-collapse collapse">
      <ul class="navbar-nav navbar-align">
        <li class="nav-item dropdown">
          <!--<a class="nav-flag btn-light rounded-circle theme-mode-toggle"
            ><i class="fas fa-moon fa-xs"></i
          ></a>-->
          <b-form-checkbox
            v-model="mode"
            value="dark"
            unchecked-value="light"
            size="sm"
            switch
            class="mt-2 mr-2 theme-mode-switch"
            @change="refresh"
          >
            Dark Mode</b-form-checkbox
          >
        </li>
        <li class="nav-item dropdown">
          <b-dropdown
            id="messages-menu"
            toggle-class="nav-icon"
            menu-class="dropdown-menu-lg py-0"
            variant="link"
            size="lg"
            right
          >
            <template #button-content>
              <div class="position-relative">
                <i class="align-middle far fa-comment fa-sm"></i>
                <span class="indicator" v-if="unreadMessageCount > 0">{{
                  unreadMessageCount
                }}</span>
              </div>
            </template>
            <template>
              <div class="dropdown-menu-header">
                <div class="position-relative">
                  {{ unreadMessageCount }} New Messages
                </div>
              </div>
              <div class="list-group">
                <b-link
                  class="list-group-item"
                  v-for="(message, index) in unreadMessages"
                  :key="index"
                  :to="{ name: 'messages', params: { id: message.key } }"
                >
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <avatar
                        :username="message.sender.name"
                        :src="message.sender.avatar"
                        :size="40"
                        class="avatar my-0"
                      ></avatar>
                    </div>
                    <div class="col-10 pl-2">
                      <div class="text-dark">{{ message.sender.name }}</div>
                      <div class="text-muted small mt-1">
                        {{ message.subject }}
                      </div>
                    </div>
                  </div>
                </b-link>
              </div>
              <div class="dropdown-menu-footer">
                <b-link class="text-muted" :to="{ name: 'messages' }"
                  >Show all messages</b-link
                >
              </div>
            </template>
          </b-dropdown>
        </li>
        <!--<li class="nav-item dropdown">
          <b-dropdown
            id="notifications-menu"
            toggle-class="nav-icon"
            menu-class="dropdown-menu-lg py-0"
            variant="link"
            size="lg"
            right
          >
            <template #button-content>
              <div class="position-relative">
                <i class="align-middle far fa-bell fa-sm"></i>
              </div>
            </template>
            <template>
              <div class="dropdown-menu-header">4 New Notifications</div>
              <div class="list-group">
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <i
                        class="text-danger fas fa-exclamation-circle fa-lg"
                      ></i>
                    </div>
                    <div class="col-10">
                      <div class="text-dark">Update completed</div>
                      <div class="text-muted small mt-1">
                        Restart server 12 to complete the update.
                      </div>
                      <div class="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <i class="text-warning" data-feather="bell"></i>
                    </div>
                    <div class="col-10">
                      <div class="text-dark">Lorem ipsum</div>
                      <div class="text-muted small mt-1">
                        Aliquam ex eros, imperdiet vulputate hendrerit et.
                      </div>
                      <div class="text-muted small mt-1">6h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <i class="text-primary" data-feather="home"></i>
                    </div>
                    <div class="col-10">
                      <div class="text-dark">Login from 192.186.1.1</div>
                      <div class="text-muted small mt-1">8h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <i class="text-success" data-feather="user-plus"></i>
                    </div>
                    <div class="col-10">
                      <div class="text-dark">New connection</div>
                      <div class="text-muted small mt-1">
                        Anna accepted your request.
                      </div>
                      <div class="text-muted small mt-1">12h ago</div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="dropdown-menu-footer">
                <a href="#" class="text-muted">Show all notifications</a>
              </div>
            </template>
          </b-dropdown>
        </li>-->
        <li class="nav-item dropdown">
          <b-dropdown id="profile-menu" toggle-class="p-0" variant="link" right>
            <template #button-content>
              <div class="nav-icon d-inline-block d-sm-none">
                <i class="align-middle fas fa-cog"></i>
              </div>
              <div class="nav-link d-none d-sm-inline-block">
                <div class="d-inline-block">
                  <avatar
                    :username="userDisplayName"
                    :src="userAvatar"
                    :size="40"
                    class="avatar mr-1"
                  ></avatar>
                </div>
                <span class="text-dark">{{ userDisplayName }}</span>
              </div>
            </template>
            <template>
              <b-dropdown-item :to="{ name: 'profile' }">
                <i class="align-middle mr-1 far fa-user"></i> Profile
              </b-dropdown-item>
              <b-dropdown-item @click="runTour()"
                ><i class="fas fa-directions"></i> Tour</b-dropdown-item
              >
              <b-dropdown-item :to="{ name: 'help' }"
                ><i class="fas fa-question-circle"></i> Help</b-dropdown-item
              >
              <b-dropdown-item @click="logout()"
                ><i class="fas fa-sign-out-alt"></i> Sign out</b-dropdown-item
              >
            </template>
          </b-dropdown>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import firebase from "firebase";
//import { db } from "../firebase";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";
import TourMixin from "../mixins/Tour";
import Avatar from "vue-avatar";
import algoliasearch from "algoliasearch/lite";

export default {
  name: "Navbar",
  mixins: [FirebaseMixin, UtilsMixin, TourMixin],
  components: {
    Avatar,
  },
  data() {
    return {
      environment: window.environment,
      isAdmin: false,
      messages: [],
      uid: "",
      userInfo: {
        name: "",
        email: "",
      },
      user: {},
      search: null,
      config: {},
      mode: "dark",
      searchClient: false,
      favorites: [],
      recents: [],
      selectedProject: null,
    };
  },
  computed: {
    ...mapState(["userProfile", "isAuthenticated"]),
    ...mapGetters([
      "userDisplayName",
      "userAvatar",
      "searchKey",
      "searchConfig",
    ]),
    searchIndex() {
      return window.environment === "production"
        ? "prod_Projects"
        : "sandbox_Projects";
    },
    algoliaSearchKey() {
      return this.searchKey;
    },
    algoliaSearchConfig() {
      return this.searchConfig;
    },
    selectedProjectName() {
      if (this.selectedProject && "name" in this.selectedProject) {
        return "Project: " + this.selectedProject.name + " ";
      } else {
        return "Projects ";
      }
    },
    unreadMessageCount() {
      return this.messages.filter((message) => !message.opened).length;
    },
    unreadMessages() {
      return this.messages
        .map((message) => {
          return { key: message[".key"], ...message };
        })
        .filter((message) => !message.opened);
    },
  },
  watch: {
    config: {
      immediate: false,
      handler(config) {
        this.setConfig(config);
      },
    },
    uid: {
      immediate: false,
      handler(uid) {
        // console.log("uid: " + uid);
        this.setUser({
          uid: uid,
          ...this.userInfo,
        });
        this.bindObject("users", uid, "user").then((snapshot) => {
          const user = snapshot.val();
          this.setUser({
            ...user,
          });
          // Show tour on new user login
          if (user.profile.show_tour) {
            this.runTour();
            this.disableTour();
          }
        });
        this.bindObject("favorites", uid, "favorites");
        this.bindObject("recents", uid, "recents");
        this.bindObject("selected", uid, "selectedProject");
        this.bindObject("messages", uid, "messages");
      },
    },
    mode: {
      immediate: false,
      handler(mode) {
        document.body.dataset["theme"] = mode;
        localStorage.setItem("theme", mode);
      },
    },
    algoliaSearchKey: {
      immediate: true,
      handler(key) {
        // console.log("SearchKey", key);
        const config = this.searchConfig;
        if (config.app_id !== "") {
          // Initialize Algolia
          this.searchClient = algoliasearch(config.app_id, key);
        }
      },
    },
    algoliaSearchConfig: {
      immediate: true,
      handler(config) {
        // console.log("SearchConfig", config);
        const key = this.searchKey;
        if (config.app_id !== "" && key !== "") {
          this.searchClient = algoliasearch(config.app_id, key);
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setConfig", "setUser", "setAuthStatus"]),
    toggleSidebar() {
      this.$emit("sidebar-toggled");
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.navigate("login");
        });
    },
    selectProject(project) {
      delete project.__position;
      delete project._highlightResult;
      console.log("Project", project.objectID);
      this.updateObject("selected", this.userProfile.uid, project, true);
    },
    refresh() {
      window.location.replace(window.location.pathname);
    },
  },
  created() {
    // Get theme from localStorage
    const theme = localStorage.getItem("theme");
    console.log("Theme", theme);
    if (theme === "light") {
      this.mode = "light";
    }
    // Bind public config
    this.bindObject("admin/config/public", null, "config");
    // Check if user is authenticated
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.setAuthStatus(true);
        this.uid = user.uid;
        this.userInfo.name = user.displayName;
        this.userInfo.email = user.email;

        // Log an id token for debugging in Postman
        // user
        //   .getIdToken(/* forceRefresh */ true)
        //   .then(function (idToken) {
        //     console.log(idToken);
        //   })
        //   .catch(function (error) {
        //     // Handle error
        //     console.log(error);
        //   });
      } else {
        // No user is signed in.
        this.setAuthStatus(false);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.theme-mode-toggle {
  width: 24px;
  height: 24px;
  padding: 0rem 0.3rem;
  line-height: 0.8;
}

.theme-mode-switch {
  padding-top: 0.08rem;
}

.theme-mode-toggle i {
  font-size: 0.6em;
}

.avatar {
  position: relative;
  top: 3px;
}

#profile-menu {
  height: 37px;
}

.dropdown-mega-list {
  min-height: 220px;
}
</style>

<style lang="scss">
.shepherd-button {
  color: #fff;
  background-color: #3f80ea;
  border-color: #3f80ea;
  border: 1px solid transparent;
  padding: 0.25rem 0.7rem;
  font-size: 0.825rem;
  line-height: 1.625;
  border-radius: 0.2rem;
}

body[data-theme="dark"] {
  .shepherd-element {
    background: #333b52;
    border: 0 solid rgba(255, 255, 255, 0.2);
    border-radius: 0.3rem;
  }
  .shepherd-has-title .shepherd-content .shepherd-header {
    background: #333b52;
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid rgba(233, 236, 239, 0.5);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .shepherd-title {
    color: #e9ecef;
  }
  .shepherd-text {
    color: #e9ecef;
  }
  .shepherd-arrow::before {
    background: #333b52;
  }
}

body[data-theme="light"] {
  .shepherd-element {
    background: #ffffff;
    border: 0 solid rgba(255, 255, 255, 0.2);
    border-radius: 0.3rem;
  }
  .shepherd-has-title .shepherd-content .shepherd-header {
    background: #f7f7f7;
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid #dee6ed;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .shepherd-title {
    color: #020202;
  }
  .shepherd-text {
    color: #020202;
  }
  .shepherd-arrow::before {
    background: #f7f7f7;
  }
}
</style>
