<template>
  <nav id="sidebar" class="sidebar">
    <simplebar data-simplebar-auto-hide="false">
      <div class="sidebar-content js-simplebar">
        <b-link :class="logoClasses" :to="{ name: 'dashboard' }">
          <img :src="logoSrc" :height="logoHeight" />
        </b-link>

        <ul class="sidebar-nav">
          <li :class="{ 'sidebar-item': true, active: route === 'dashboard' }">
            <b-link class="sidebar-link" :to="{ name: 'dashboard' }">
              <i class="align-middle fas fa-chart-area"></i>
              <span class="align-middle">Dashboard</span>
              <!--<span class="badge badge-sidebar-primary">5</span>-->
            </b-link>
          </li>
          <li :class="{ 'sidebar-item': true, active: route === 'schemas' }">
            <b-link class="sidebar-link" :to="{ name: 'schemas' }">
              <i class="align-middle fas fa-table"></i>
              <span class="align-middle">Schemas</span>
            </b-link>
          </li>
          <li
            :class="{
              'sidebar-item': true,
              active:
                route === 'apis' ||
                route === 'credentials' ||
                route === 'logs' ||
                route === 'test',
            }"
          >
            <a
              v-b-toggle.apis
              data-toggle="collapse"
              class="sidebar-link collapsed"
            >
              <i class="align-middle fas fa-server"></i>
              <span class="align-middle">APIs</span>
            </a>
            <b-collapse
              id="apis"
              v-model="apisOpen"
              tag="ul"
              class="sidebar-dropdown list-unstyled"
              data-parent="#sidebar"
            >
              <li :class="{ 'sidebar-item': true, active: route === 'apis' }">
                <b-link class="sidebar-link" :to="{ name: 'apis' }"
                  >Configure</b-link
                >
              </li>
              <li
                :class="{
                  'sidebar-item': true,
                  active: route === 'credentials',
                }"
              >
                <b-link class="sidebar-link" :to="{ name: 'credentials' }"
                  >Credentials</b-link
                >
              </li>
              <li :class="{ 'sidebar-item': true, active: route === 'logs' }">
                <b-link class="sidebar-link" :to="{ name: 'logs' }"
                  >Logs</b-link
                >
              </li>
              <li :class="{ 'sidebar-item': true, active: route === 'test' }">
                <b-link class="sidebar-link" :to="{ name: 'test' }"
                  >Test</b-link
                >
              </li>
            </b-collapse>
          </li>
          <li :class="{ 'sidebar-item': true, active: route === 'files' }">
            <b-link class="sidebar-link" :to="{ name: 'files' }">
              <i class="align-middle fas fa-file"></i>
              <span class="align-middle">Flat Files</span>
            </b-link>
          </li>
          <li :class="{ 'sidebar-item': true, active: route === 'data' }">
            <b-link class="sidebar-link" :to="{ name: 'data' }">
              <i class="align-middle fas fa-database"></i>
              <span class="align-middle">Data Stores</span>
            </b-link>
          </li>
        </ul>

        <div class="sidebar-cta">
          <div class="sidebar-cta-content">
            <strong class="d-inline-block mb-2">Hitting Plan Limits?</strong>
            <div class="mb-3 text-sm">
              Upgrade now to increase your project's resources.
            </div>
            <b-button
              :to="{ name: 'pricing' }"
              variant="primary"
              class="btn-block"
            >
              Upgrade Now
            </b-button>
          </div>
        </div>
      </div>
      <div
        :class="{
          'sidebar-item': true,
          'text-center': sidebarState === 'collapsed',
          'text-right': sidebarState === 'open',
        }"
      >
        <b-link class="sidebar-link" @click="toggleState">
          <i
            :class="{
              fas: true,
              'sidebar-state-toggle': true,
              'fa-angle-double-right': sidebarState === 'collapsed',
              'fa-angle-double-left': sidebarState === 'open',
              'mr-0': true,
            }"
          ></i>
        </b-link>
      </div>
    </simplebar>
  </nav>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  props: {
    sidebarOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sidebarState: "open",
      logo: {
        light: {
          open: require("../assets/img/logo_light.png"),
          collapsed: require("../assets/img/logo_img_light.png"),
        },
        dark: {
          open: require("../assets/img/logo.png"),
          collapsed: require("../assets/img/logo_img.png"),
        },
      },
      apisOpen: false,
    };
  },
  components: {
    simplebar,
  },
  computed: {
    sidebarWidth() {
      return this.sidebarState === "collapsed" ? 68 : 260;
    },
    logoSrc() {
      return this.sidebarState === "collapsed"
        ? this.logo[this.theme].collapsed
        : this.logo[this.theme].open;
    },
    logoHeight() {
      return this.sidebarState === "collapsed" ? "30px" : "40px";
    },
    logoClasses() {
      const classes = ["sidebar-brand"];
      if (this.sidebarState === "collapsed") {
        classes.push("sidebar-brand-sm");
      }
      return classes;
    },
    theme() {
      return document.body.dataset["theme"];
    },
    route() {
      return this.$route.name;
    },
  },
  watch: {
    route: {
      immediate: true,
      handler(route) {
        if (
          route === "apis" ||
          route === "credentials" ||
          route === "logs" ||
          route === "test"
        ) {
          this.apisOpen = true;
        }
      },
    },
    sidebarOpen: {
      immediate: true,
      handler(state) {
        if (document.querySelector("#sidebar")) {
          if (state) {
            // Sidebar is open
            document.querySelector("#sidebar").style.marginLeft = "0px";
            document.querySelector("#main").style.width =
              "calc(100% - " + this.sidebarWidth + "px)";
          } else {
            // Sidebar is closed
            document.querySelector("#sidebar").style.marginLeft =
              this.sidebarWidth * -1 + "px";
            document.querySelector("#main").style.width = "100%";
          }
        }
      },
    },
  },
  methods: {
    toggleState() {
      if (this.sidebarState === "open") {
        this.sidebarState = "collapsed";
        document.body.dataset["sidebarBehavior"] = "compact";
        if (this.sidebarOpen) {
          // Sidebar is open
          document.querySelector("#sidebar").style.marginLeft = "0px";
          document.querySelector("#main").style.width =
            "calc(100% - " + this.sidebarWidth + "px)";
        } else {
          // Sidebar is closed
          document.querySelector("#sidebar").style.marginLeft =
            this.sidebarWidth * -1 + "px";
          document.querySelector("#main").style.width = "100%";
        }
      } else {
        this.sidebarState = "open";
        document.body.dataset["sidebarBehavior"] = "sticky";
      }
    },
  },
};
</script>

<style scoped>
.sidebar-brand-sm {
  padding-left: 0.85rem;
}
</style>
