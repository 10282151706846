var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sidebar",attrs:{"id":"sidebar"}},[_c('simplebar',{attrs:{"data-simplebar-auto-hide":"false"}},[_c('div',{staticClass:"sidebar-content js-simplebar"},[_c('b-link',{class:_vm.logoClasses,attrs:{"to":{ name: 'dashboard' }}},[_c('img',{attrs:{"src":_vm.logoSrc,"height":_vm.logoHeight}})]),_c('ul',{staticClass:"sidebar-nav"},[_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'dashboard' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'dashboard' }}},[_c('i',{staticClass:"align-middle fas fa-chart-area"}),_c('span',{staticClass:"align-middle"},[_vm._v("Dashboard")])])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'schemas' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'schemas' }}},[_c('i',{staticClass:"align-middle fas fa-table"}),_c('span',{staticClass:"align-middle"},[_vm._v("Schemas")])])],1),_c('li',{class:{
            'sidebar-item': true,
            active:
              _vm.route === 'apis' ||
              _vm.route === 'credentials' ||
              _vm.route === 'logs' ||
              _vm.route === 'test',
          }},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.apis",modifiers:{"apis":true}}],staticClass:"sidebar-link collapsed",attrs:{"data-toggle":"collapse"}},[_c('i',{staticClass:"align-middle fas fa-server"}),_c('span',{staticClass:"align-middle"},[_vm._v("APIs")])]),_c('b-collapse',{staticClass:"sidebar-dropdown list-unstyled",attrs:{"id":"apis","tag":"ul","data-parent":"#sidebar"},model:{value:(_vm.apisOpen),callback:function ($$v) {_vm.apisOpen=$$v},expression:"apisOpen"}},[_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'apis' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'apis' }}},[_vm._v("Configure")])],1),_c('li',{class:{
                'sidebar-item': true,
                active: _vm.route === 'credentials',
              }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'credentials' }}},[_vm._v("Credentials")])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'logs' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'logs' }}},[_vm._v("Logs")])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'test' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'test' }}},[_vm._v("Test")])],1)])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'files' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'files' }}},[_c('i',{staticClass:"align-middle fas fa-file"}),_c('span',{staticClass:"align-middle"},[_vm._v("Flat Files")])])],1),_c('li',{class:{ 'sidebar-item': true, active: _vm.route === 'data' }},[_c('b-link',{staticClass:"sidebar-link",attrs:{"to":{ name: 'data' }}},[_c('i',{staticClass:"align-middle fas fa-database"}),_c('span',{staticClass:"align-middle"},[_vm._v("Data Stores")])])],1)]),_c('div',{staticClass:"sidebar-cta"},[_c('div',{staticClass:"sidebar-cta-content"},[_c('strong',{staticClass:"d-inline-block mb-2"},[_vm._v("Hitting Plan Limits?")]),_c('div',{staticClass:"mb-3 text-sm"},[_vm._v(" Upgrade now to increase your project's resources. ")]),_c('b-button',{staticClass:"btn-block",attrs:{"to":{ name: 'pricing' },"variant":"primary"}},[_vm._v(" Upgrade Now ")])],1)])],1),_c('div',{class:{
        'sidebar-item': true,
        'text-center': _vm.sidebarState === 'collapsed',
        'text-right': _vm.sidebarState === 'open',
      }},[_c('b-link',{staticClass:"sidebar-link",on:{"click":_vm.toggleState}},[_c('i',{class:{
            fas: true,
            'sidebar-state-toggle': true,
            'fa-angle-double-right': _vm.sidebarState === 'collapsed',
            'fa-angle-double-left': _vm.sidebarState === 'open',
            'mr-0': true,
          }})])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }