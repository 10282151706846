<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row text-muted">
        <div class="col-6 text-left">
          <ul class="list-inline">
            <li class="list-inline-item">
              <b-link class="text-muted" :to="{ name: 'help' }">Support</b-link>
            </li>
            <li class="list-inline-item">
              <b-link class="text-muted" :to="{ name: 'privacy' }"
                >Privacy</b-link
              >
            </li>
            <li class="list-inline-item">
              <b-link class="text-muted" :to="{ name: 'terms' }"
                >Terms of Service</b-link
              >
            </li>
          </ul>
        </div>
        <div class="col-6 text-right">
          <p class="mb-0">
            &copy; {{ year }} -
            <a href="index.html" class="text-muted">API Faker</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "Footer",
  computed: {
    year() {
      const dt = DateTime.now();
      return dt.toFormat("yyyy");
    },
  },
};
</script>
