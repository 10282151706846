import FirebaseMixin from "./Firebase";
import { mapState } from "vuex";

export default {
  mixins: [FirebaseMixin],
  data() {
    return {
      bind: [],
    };
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    disableTour() {
      const uid = this.userProfile.uid;
      console.log("Update Tour Preference", uid);
      this.updateObject(`users/${uid}/profile`, null, { show_tour: false });
    },
    runTour() {
      const _this = this;
      const tour = this.$shepherd({
        useModalOverlay: false,
        defaultStepOptions: {
          title: "API Faker Tour",
          cancelIcon: {
            enabled: true,
          },
          scrollTo: { behavior: "smooth", block: "center" },
        },
      });
      // Welcome step
      tour.addStep({
        id: "welcome",
        text:
          "Welcome to API Faker! Before you start building APIs, let's walk through the basics of the interface.",
        buttons: [
          {
            text: "Skip",
            action: tour.complete,
          },
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Project Menu
      tour.addStep({
        attachTo: { element: "#project-menu", on: "bottom" },
        text:
          "This is the Project Menu. The menu allows you to manage your projects and switch which project you are working on.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            // setTimeout(() => {
            //   this.$refs.project_menu.show();
            // }, 500);
          },
          "before-hide": () => {
            // this.$refs.project_menu.hide();
          },
        },
      });
      // Sidebar
      tour.addStep({
        attachTo: { element: "#sidebar", on: "right" },
        text:
          "The side bar provides access to the Dashboard, Schemas, APIs, Flat Files, and Data Stores for the currently selected project.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Sidebar Toggle
      tour.addStep({
        attachTo: { element: ".sidebar-toggle", on: "bottom" },
        text: "Click the toggle to hide the sidebar.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Sidebar Collapse
      tour.addStep({
        attachTo: { element: ".sidebar-state-toggle", on: "bottom" },
        text: "Click this toggle to switch to an icon-only sidebar.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Theme Mode Switch
      tour.addStep({
        attachTo: { element: ".theme-mode-switch", on: "bottom" },
        text: "Flip the switch to toggle between dark and light mode.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Notifications
      tour.addStep({
        attachTo: { element: "#messages-menu", on: "bottom" },
        text:
          "Notifications of important messages such as project invites will appear here. Click to view all of your messages.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Profile Menu
      tour.addStep({
        attachTo: { element: "#profile-menu", on: "bottom" },
        text:
          "Change your profile settings, repeat this tour, get help, and sign out here.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Dashboard
      tour.addStep({
        attachTo: { element: ".title", on: "bottom" },
        text:
          "This is your Project Dashboard. It shows your current plan usage as well as your open support tickets.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("dashboard");
          },
        },
      });
      // Schemas
      tour.addStep({
        text:
          "Here are the schemas for your project. Schemas allow you to define record layouts for exporting data from APIs and in flat files.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("schemas");
          },
        },
      });
      // Schema buttons
      tour.addStep({
        attachTo: { element: ".new-buttons", on: "bottom-end" },
        text:
          "Schemas are organized in folders. Use the New Schema button to create a new schema, or the New Folder button to create a folder within the current folder.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Schema search
      tour.addStep({
        attachTo: { element: ".search", on: "bottom-start" },
        text:
          "The search lets you search for a schema across all folders. It will display the results as you type.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // APIs Configure
      tour.addStep({
        text:
          "Here you can configure the APIs for your project. Notice that APIs uses the same folder-based organization as Schemas.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("apis");
          },
        },
      });
      // APIs Credentials
      tour.addStep({
        text: "Create credentials here for authenticating API requests.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("credentials");
          },
        },
      });
      // APIs Logs
      tour.addStep({
        text:
          "All requests to the APIs in your project will be logged here. The logs can be searched and filtered by the API endpoint, log level, and date.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("logs");
          },
        },
      });
      // APIs Test
      tour.addStep({
        text:
          "Here you test out your APIs. Specify the details of your request at the top and the response will be displayed at the bottom.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("test");
          },
        },
      });
      // APIs Test URL
      tour.addStep({
        attachTo: { element: "#request-url-bar", on: "bottom" },
        text:
          "Select the HTTP method and API endpoint for your request. The path will automatically be populated with your query params. Query params, authorization details, headers, and body content can be specified below. Hit Send when you request is ready.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
      });
      // Flat Files
      tour.addStep({
        text:
          "Want to create a delimited file of test records? Use Flat Files for that. Flat Files let you download a specified number of records from a Schema in a delimited format.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("files");
          },
        },
      });
      // Data Stores
      tour.addStep({
        text:
          "Data Stores house your JSON data for use in APIs and Flat Files. Stored data can be commingled with fake data using Schemas. Data can be manually added here or through an API.",
        buttons: [
          {
            text: "Next",
            action: tour.next,
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("data");
          },
        },
      });
      // Completion
      tour.addStep({
        text:
          "Ready to get started? If you still have any questions, try checking out out Knowledge Base. Or, you can always submit a support ticket.",
        buttons: [
          {
            text: "Get Started",
            action: tour.complete,
          },
          {
            text: "Knowledge Base",
            action() {
              _this.navigate("help", { page: "kb" });
              return this.complete();
            },
          },
        ],
        when: {
          "before-show": () => {
            this.navigate("dashboard");
          },
        },
      });

      tour.start();
    },
  },
};
