import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import { rtdbPlugin } from "vuefire";

Vue.use(rtdbPlugin);

const prodConfig = {
  apiKey: "AIzaSyAgQwxhroprtDDfgM_NF0gvS4ASnds-Eqo",
  authDomain: "api-faker.firebaseapp.com",
  databaseURL: "https://api-faker-default-rtdb.firebaseio.com",
  //databaseURL: "http://localhost:9000/?ns=api-faker-default-rtdb",
  projectId: "api-faker",
  storageBucket: "api-faker.appspot.com",
  messagingSenderId: "50094184839",
  appId: "1:50094184839:web:ecefe2a94c07115ea69b22",
  measurementId: "G-DR7KMRWRGS",
};

const sandboxConfig = {
  apiKey: "AIzaSyAgQwxhroprtDDfgM_NF0gvS4ASnds-Eqo",
  authDomain: "api-faker.firebaseapp.com",
  databaseURL: "https://api-faker-sandbox.firebaseio.com",
  //databaseURL: "http://localhost:9000/?ns=api-faker-sandbox",
  projectId: "api-faker",
  storageBucket: "api-faker.appspot.com",
  messagingSenderId: "50094184839",
  appId: "1:50094184839:web:ecefe2a94c07115ea69b22",
  measurementId: "G-DR7KMRWRGS",
};

// Handle different environments
if (
  window.location.host === "app.apifaker.dev" ||
  window.location.host === "api-faker.web.app" ||
  window.location.host === "api-faker.firebaseapp.com"
) {
  firebase.initializeApp(prodConfig);
} else {
  firebase.initializeApp(sandboxConfig);
}

window.firebase = firebase;

const auth = firebase.auth();
const db = firebase.database();
const storage = firebase.storage();

const checkSubscription = () => {
  return new Promise((resolve, reject) => {
    if (auth.currentUser) {
      auth.currentUser
        .getIdTokenResult()
        .then((result) => {
          const subscribed =
            "paid" in result.claims ||
            "vip" in result.claims ||
            "admin" in result.claims
              ? true
              : false;
          resolve(subscribed);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve(false);
    }
  });
};

export { auth, db, storage, checkSubscription };
