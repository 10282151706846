<template>
  <div id="app" class="wrapper">
    <sidebar v-if="showNav" :sidebar-open="sidebarOpen" />
    <div id="main" class="main">
      <navbar v-if="showNav" v-on:sidebar-toggled="updateSidebarOpen" />
      <main class="content">
        <!-- <div class="container-fluid p-0">
          <div id="content-section-ad-before"></div>
        </div> -->
        <div class="container-fluid p-0">
          <router-view v-if="!loading" />
          <loader color="#3F80EA" class="mx-auto my-5" v-if="loading" />
        </div>
        <!-- <div class="container-fluid p-0">
          <div id="content-section-ad-after"></div>
        </div> -->
      </main>
      <footerbar />
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
// import { checkSubscription } from "@/firebase";
// import "@/assets/scss/dark.scss";
import Sidebar from "@/components/Sidebar";
import Navbar from "@/components/Navbar";
import Footerbar from "@/components/Footer";
import Loader from "./components/Loader";

export default {
  name: "App",
  components: {
    Sidebar,
    Navbar,
    Footerbar,
    Loader,
  },
  data() {
    return {
      loading: true,
      authorized: false,
      sidebarOpen: true,
    };
  },
  computed: {
    showNav() {
      return (
        this.authorized &&
        this.$route.name !== "login" &&
        this.$route.name !== "signup" &&
        this.$route.name !== "provider-signup"
      );
    },
  },
  methods: {
    updateSidebarOpen() {
      const state = this.sidebarOpen ? false : true;
      console.log("updateSidebarOpen", state);
      this.sidebarOpen = state;
    },
    displayAds() {
      const googletag = window.googletag;
      googletag.cmd.push(() => {
        googletag.display("content-section-ad-before");
        googletag.display("content-section-ad-after");
      });
    },
  },
  beforeCreate() {
    // Set siderbar open state based on viewport size
    this.sidebarOpen = window.innerWidth >= 975;
    // Get last used theme from localstorage
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      import("@/assets/scss/light.scss");
      document.body.dataset["theme"] = "light";
    } else {
      import("@/assets/scss/dark.scss");
      document.body.dataset["theme"] = "dark";
    }
  },
  created() {
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      document.body.dataset["theme"] = "light";
    } else {
      document.body.dataset["theme"] = "dark";
    }
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.authorized = true;
        window.authorized = true;
        window.user = user;

        // Load ads for free users
        // checkSubscription()
        //   .then((subscribed) => {
        //     if (!subscribed) {
        //       this.displayAds();
        //     }
        //   })
        //   .catch((error) => {
        //     console.error(error.message);
        //   });
      }
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
body[data-theme="dark"] {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #293042 !important;
  }
}

.adsense {
  width: 970px;
  height: 90px;
  display: block;
}
.adsense:before {
  display: none !important;
}
.adsense ins {
  width: 100%;
  height: 100%;
  display: block;
}

@media screen and (max-width: 1024px) {
  .adsense {
    width: 728px;
    height: 90px;
  }
  .adsense:before {
    content: "1024";
  }
}

@media screen and (max-width: 800px) {
  .adsense {
    width: 468px;
    height: 60px;
  }
  .adsense:before {
    content: "800";
  }
}
</style>
