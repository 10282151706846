import Vue from "vue";
import VueRouter from "vue-router";
import { auth, checkSubscription } from "../firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/new",
    name: "projects-new",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "../views/projects/ProjectNew.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/import",
    name: "projects-import",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "../views/projects/ProjectImport.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:slug/edit",
    name: "projects-edit",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "../views/projects/ProjectEdit.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: () =>
      import(
        /* webpackChunkName: "projects" */ "../views/projects/Projects.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schemas/new",
    name: "schemas-new",
    component: () =>
      import(
        /* webpackChunkName: "schemas" */ "../views/schemas/SchemaNew.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schemas/:id/edit",
    name: "schemas-edit",
    component: () =>
      import(
        /* webpackChunkName: "schemas" */ "../views/schemas/SchemaEdit.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schemas/:path*",
    name: "schemas",
    component: () =>
      import(/* webpackChunkName: "schemas" */ "../views/schemas/Schemas.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data/new",
    name: "data-new",
    component: () =>
      import(/* webpackChunkName: "data" */ "../views/data/DataNew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data/:id/edit",
    name: "data-edit",
    component: () =>
      import(/* webpackChunkName: "data" */ "../views/data/DataEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data/:path*",
    name: "data",
    component: () =>
      import(/* webpackChunkName: "data" */ "../views/data/Data.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/apis/new",
    name: "apis-new",
    component: () =>
      import(/* webpackChunkName: "apis" */ "../views/apis/APINew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/apis/:id/edit",
    name: "apis-edit",
    component: () =>
      import(/* webpackChunkName: "apis" */ "../views/apis/APIEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/apis/:path*",
    name: "apis",
    component: () =>
      import(/* webpackChunkName: "apis" */ "../views/apis/APIs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/credentials/new",
    name: "credentials-new",
    component: () =>
      import(
        /* webpackChunkName: "credentials" */ "../views/credentials/CredentialsNew.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/credentials/:id/edit",
    name: "credentials-edit",
    component: () =>
      import(
        /* webpackChunkName: "credentials" */ "../views/credentials/CredentialsEdit.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/credentials/:path*",
    name: "credentials",
    component: () =>
      import(
        /* webpackChunkName: "credentials" */ "../views/credentials/Credentials.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs",
    name: "logs",
    component: () => import(/* webpackChunkName: "logs" */ "../views/Logs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import(/* webpackChunkName: "test" */ "../views/Test.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/files/new",
    name: "files-new",
    component: () =>
      import(/* webpackChunkName: "files" */ "../views/files/FileNew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/files/:id/edit",
    name: "files-edit",
    component: () =>
      import(/* webpackChunkName: "files" */ "../views/files/FileEdit.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/files/:path*",
    name: "files",
    component: () =>
      import(/* webpackChunkName: "files" */ "../views/files/Files.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/help/articles/:path*",
    name: "read-article",
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/help/ReadArticle.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/help/tickets/new",
    name: "submit-ticket",
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/help/SubmitTicket.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/help/tickets/:id",
    name: "read-ticket",
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/help/ReadTicket.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/help/:page?/:path*",
    name: "help",
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/help/Help.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/messages/:id?",
    name: "messages",
    component: () =>
      import(
        /* webpackChunkName: "messages" */ "../views/messages/Messages.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/:tab?",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/account/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/Pricing.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/UpgradeSuccess.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignUp.vue"),
  },
  {
    path: "/signup/provider",
    name: "provider-signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/ProviderSignUp.vue"),
  },
  {
    path: "/login/:page?",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Privacy.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Terms.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresSub = to.matched.some((record) => record.meta.requiresSub);

  // console.log(to);
  auth.onAuthStateChanged(async (user) => {
    let authorized;
    if (user) {
      authorized = true;
    } else {
      authorized = false;
    }

    let subscribed = true;
    try {
      subscribed = await checkSubscription();
    } catch (e) {
      subscribed = false;
      console.log(e);
    }

    if (requiresAuth && !authorized && to.name !== "login") {
      next({ name: "login" });
    } else if (requiresSub && authorized && !subscribed) {
      next({ name: "upgrade" });
    } else next();
  });
});

export default router;
